//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PictureResponsive from '~/components/PictureResponsive.vue';

export default {
  name: 'SplitViewOverlay',
  components: {
    PictureResponsive,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    imgUrl: {
      type: String,
      default: '',
    },
    reverse: Boolean,
    column: Boolean,

  },

};
