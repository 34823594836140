//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const pictureTypeClassMap = {
  cover: 'picture--cover',
  block: 'picture--block',
};

export default {
  name: 'PictureResponsive',
  props: {
    defaultUrl: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    mobileImgPath: {
      type: String,
      default: '',
    },
    mobileImgSizes: {
      type: String,
      default: '100vw',
    },
    desktopImgPath: {
      type: String,
      default: '',
    },
    desktopImgSizes: {
      type: String,
      default: '100vw',
    },
    imgStyle: {
      type: String,
      default: '',
    },
    imgAlt: {
      type: String,
      default: '',
    },
    mobilePositioning: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loaded: 0,
  }),
  computed: {
    pictureTypeClass() {
      return pictureTypeClassMap[this.type] || '';
    },
  },
  created() {
    this.pictures = [];
    if (this.mobileImgPath) {
      this.pictures.push(
        {
          srcset:
              `https://cdn3.joinfightcamp.com/750x750/filters:format(webp)/${this.mobileImgPath} 750w,
               https://cdn3.joinfightcamp.com/820x820/filters:format(webp)/${this.mobileImgPath} 820w,
               https://cdn3.joinfightcamp.com/1000x1000/filters:format(webp)/${this.mobileImgPath} 1000w,
               https://cdn3.joinfightcamp.com/1526x1526/filters:format(webp)/${this.mobileImgPath} 1526w`,
          type: 'image/webp',
          media: '(max-width: 1200px)',
          sizes: this.mobileImgSizes,
        },
      );
    }

    if (this.desktopImgPath) {
      this.pictures.push(
        {
          srcset: `https://cdn3.joinfightcamp.com/500x0/filters:format(webp)/${this.desktopImgPath} 500w,
                    https://cdn3.joinfightcamp.com/800x0/filters:format(webp)/${this.desktopImgPath} 800w,
                    https://cdn3.joinfightcamp.com/1000x0/filters:format(webp)/${this.desktopImgPath} 1000w,
                    https://cdn3.joinfightcamp.com/1578x0/filters:format(webp)/${this.desktopImgPath} 1578w,
                    https://cdn3.joinfightcamp.com/2000x0/filters:format(webp)/${this.desktopImgPath} 2000w,
                    https://cdn3.joinfightcamp.com/3000x0/filters:format(webp)/${this.desktopImgPath} `,
          type: 'image/webp',
          sizes: this.desktopImgSizes,
        },
      );
    }

    if (this.mobileImgPath) {
      this.pictures.push(
        {
          srcset: `https://cdn3.joinfightcamp.com/750x750/${this.mobileImgPath} 750w,
                    https://cdn3.joinfightcamp.com/820x820/${this.mobileImgPath} 820w,
                    https://cdn3.joinfightcamp.com/1000x1000/${this.mobileImgPath} 1000w,
                    https://cdn3.joinfightcamp.com/1526x1526/${this.mobileImgPath} 1526w`,
          type: 'image/jpeg',
          media: '(max-width: 1200px)',
          sizes: this.mobileImgSizes,
        },
      );
    }

    if (this.desktopImgPath) {
      this.pictures.push(
        {
          srcset: `https://cdn3.joinfightcamp.com/500x0/${this.desktopImgPath} 500w,
                      https://cdn3.joinfightcamp.com/800x0/${this.desktopImgPath} 800w,
                      https://cdn3.joinfightcamp.com/1000x0/${this.desktopImgPath} 1000w,
                      https://cdn3.joinfightcamp.com/1578x0/${this.desktopImgPath} 1578w,
                      https://cdn3.joinfightcamp.com/2000x0/${this.desktopImgPath} 2000w,
                      https://cdn3.joinfightcamp.com/3000x0/${this.desktopImgPath} `,
          type: 'image/jpeg',
          sizes: this.desktopImgSizes,
        },
      );
    }
  },
};
